import Container from 'components/common/container';
import { flexBetween, flexCenter } from 'config/mixins';
import theme from 'config/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { fontSizes, fontWeights, radii, colors } = theme;

export const Content = styled(Container)`
	padding: 60px 14vw 80px 14vw;
	margin-bottom: 80px;
`;

export const Card = styled.li`
	${flexBetween};
	margin-bottom: 40px;
	width: 100%;
	background-color: ${colors.white};
	border-radius: ${radii.default};
	padding: 30px 40px;
	box-shadow: 0 40px 100px 0 rgba(0, 0, 0, 0.1);
`;

export const ProfilePicture = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 50%;
`;

export const ProfileContainer = styled.div`
	${flexCenter};
`;

export const NameContainer = styled.div`
	${flexCenter};
	flex-direction: column;
	margin-left: 20px;
`;

export const Name = styled.p`
	font-weight: ${fontWeights.bold};
	width: 100%;
`;

export const DetailsContainer = styled.div`
	${flexCenter};
	margin-left: 100px;
`;

export const DetailContainer = styled.div`
	min-width: 60px;
	margin-right: 40px;
	max-width: 200px;
`;

export const DetailLabel = styled.p`
	color: ${colors.lightBlue};
	font-size: ${fontSizes.small};
	margin-bottom: 5px;
`;

export const ViewApplicant = styled(Link)`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.medium};
	color: ${colors.pink};
	text-decoration: none;
`;
