import Footer from 'components/common/footer';
import Header from 'components/common/header';
import Main from 'components/common/main';
import PageTitle from 'components/common/page-title';
import Paragraph from 'components/common/paragraph';
import Title from 'components/common/title';
import theme from 'config/theme';
import React, { useState } from 'react';
import Helmet from 'react-helmet';

import {
	Card,
	Content,
	DetailContainer,
	DetailLabel,
	DetailsContainer,
	Name,
	NameContainer,
	ProfileContainer,
	ProfilePicture,
	ViewApplicant,
} from './styles';

const { colors } = theme;

const styleOverride = {
	paragraph: { maxWidth: '600px', marginBottom: '60px' },
	title: { marginBottom: '60px' },
};

const Applications = () => {
	// eslint-disable-next-line
	const [requests, setRequets] = useState([
		{
			id: 'foobarbaz',
			profilePicture: 'https://randomuser.me/api/portraits/lego/1.jpg',
			name: 'Name',
			surname: 'Surname',
			property: 'Property Name',
			rooms: 1,
			requested: new Date(),
			status: 'Pending',
		},
	]);
	// eslint-disable-next-line
	const [acceptedApplicants, setAcceptedApplicants] = useState([]);
	// eslint-disable-next-line
	const [deniedApplicants, setDeniedApplicants] = useState([]);

	return (
		<Main>
			<Helmet>
				<title>Applications</title>
				<meta name="description" content="Your applications" />
			</Helmet>
			<Header bgColor={colors.white} searchable />
			<Content bgColor={colors.lightGray}>
				<PageTitle>Applications</PageTitle>
				<Paragraph style={styleOverride.paragraph}>
					Lorem ipsum dolor amet pop-up put a bird on it hashtag man bun forage XOXO. Man braid
					schlitz gluten-free.
				</Paragraph>
				<Title style={styleOverride.title}>Requests</Title>
				<ul>
					{requests.map(
						({ id, profilePicture, name, surname, property, rooms, requested, status }) => (
							<Card key={id}>
								<ProfileContainer>
									<ProfilePicture src={profilePicture} alt={`$${name} ${surname}`} />
									<NameContainer>
										<Name>{name}</Name>
										<Name>{surname}</Name>
									</NameContainer>
								</ProfileContainer>

								<DetailsContainer>
									<DetailContainer>
										<DetailLabel>Property</DetailLabel>
										<p>{property}</p>
									</DetailContainer>

									<DetailContainer>
										<DetailLabel>Rooms</DetailLabel>
										<p>{rooms}</p>
									</DetailContainer>

									<DetailContainer>
										<DetailLabel>Requested</DetailLabel>
										<p>
											{requested
												.toLocaleDateString()
												.split('/')
												.join('.')}
										</p>
									</DetailContainer>

									<DetailContainer>
										<DetailLabel>Status</DetailLabel>
										<p>{status}</p>
									</DetailContainer>
								</DetailsContainer>
								<ViewApplicant to="/applications-detail">View Application</ViewApplicant>
							</Card>
						),
					)}
				</ul>
				<Title style={styleOverride.title}>Accepted Applicants</Title>
				<Title style={styleOverride.title}>Denied Applicants</Title>
			</Content>
			<Footer />
		</Main>
	);
};

export default Applications;
