import theme from 'config/theme';
import styled from 'styled-components';

const { fontSizes, fontWeights, fonts } = theme;

const Title = styled.h3`
	padding-right: 20px;
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	margin-bottom: 40px;
`;

export default Title;
